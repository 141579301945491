<template>
	<UserPick 
		@onUserClick="onUserClick($event)"
	/>
</template>

<script>
	import PortalMixins from '@/mixins/PortalMixins';
	
	export default {
		mixins: [
			PortalMixins,
		],
		
		components: {
			'UserRow': () => import('@/components/users/UserRow.vue'),
			'UserPick': () => import('@/components/users/UserPick.vue'),
		},
		
		data(){
			return {
				loading: null,
			}
		},
		
		methods: {
			onUserClick( user ){
				this.$router.push({
					name: 'portal-departments', 
					params: { 
						accountId: this.accountId,
						username: user.username,
					}
				});
			},
			
			async listDepartments(){
				try {
					const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/departments', {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					this.$store.commit('setDepartments', response.data);
				} 
				catch (e) {
					this.error = { 
						type: 'error', 
						text: e.message || 'Kunne ikke hente avdeligner'
					};
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async listCampaigns(){
				try {
					const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/portal/campaigns', {
						headers: {
							authorization: this.account.accessToken,
						},
					});
					
					this.$store.commit('setCampaigns', response.data);
				} 
				catch (e) {
					this.error = { 
						type: 'error', 
						text: e.message || 'Kunne ikke hente kampanjer'
					};
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
		},
		
		async mounted(){
			this.loading = 'fetching';
			// await this.listUsers();
			await this.listDepartments();
			await this.listCampaigns();
			this.loading = null;
		},
	}
</script>